<template>
  <div>
    <div class="srA">
      <div class="bts">动态评估</div>
      <div>
        <ul class="content-sr padding-20 xz">
          <li></li>
          <li>中心定位</li>
          <li>移动度</li>
          <li>泪液交换</li>
        </ul>
        <ul class="content-sr xz">
          <li>OD</li>
          <li>
            <el-select v-model="postData.CL_Recheck_LocationOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in zxdw"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.CL_Recheck_ActivityOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in ydd"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.CL_Recheck_LYJHOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in lyjh"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
        <ul class="content-sr padding-20 xz">
          <li>OS</li>
          <li>
            <el-select v-model="postData.CL_Recheck_LocationOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in zxdw"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.CL_Recheck_ActivityOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in ydd"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.CL_Recheck_LYJHOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in lyjh"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
      </div>
    </div>

    <div class="srA">
      <div class="bts">静态评估</div>
      <div>
        <ul class="content-sr padding-20 xz">
          <li></li>
          <li>中央光学区</li>
          <li>反转区</li>
          <li>着陆区</li>
          <li>边缘区</li>
        </ul>
        <ul class="content-sr xz">
          <li>OD</li>
          <li>
            <el-select v-model="postData.CL_Recheck_CenterOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in zygxq"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.CL_Recheck_ReversalOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in fzq"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.CL_Recheck_OnlandOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in zlq"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.CL_Recheck_EdgeOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in byq"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
        <ul class="content-sr padding-20 xz">
          <li>OS</li>
          <li>
            <el-select v-model="postData.CL_Recheck_CenterOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in zygxq"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.CL_Recheck_ReversalOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in fzq"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.CL_Recheck_OnlandOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in zlq"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.CL_Recheck_EdgeOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in byq"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
      </div>
    </div>

    <div class="srA">
      <div class="bts" style="opacity: 0;">静态评估</div>
      <div>
        <ul class="content-sr padding-20 xz">
          <li></li>
          <li>镜片直径</li>
          <li>佩戴情况</li>
        </ul>
        <ul class="content-sr xz">
          <li>OD</li>
          <li>
            <el-select v-model="postData.CL_Recheck_MatchingOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in jpzj"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.CL_Recheck_WearingOD" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in pdqk"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
        <ul class="content-sr padding-20 xz">
          <li>OS</li>
          <li>
            <el-select v-model="postData.CL_Recheck_MatchingOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in jpzj"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select v-model="postData.CL_Recheck_WearingOS" filterable allow-create  placeholder="请选择">
              <el-option
                  v-for="item in pdqk"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
      </div>
    </div>

    <div @click="$router.push({path: '/corneaContact/relatedTest'})" class="bottom"><el-button type="primary">相关检查</el-button></div>
  </div>
</template>

<script>
export default {
  name: "wearGlasses",
  data() {
    return {
      value: '',
      postData: {
        CL_Recheck_LocationOD: "",
        CL_Recheck_ActivityOD: "",
        CL_Recheck_LYJHOD: "",
        CL_Recheck_LocationOS: "",
        CL_Recheck_ActivityOS: "",
        CL_Recheck_LYJHOS: "",

        CL_Recheck_CenterOD: "",
        CL_Recheck_ReversalOD: "",
        CL_Recheck_OnlandOD: "",
        CL_Recheck_EdgeOD: "",
        CL_Recheck_CenterOS: "",
        CL_Recheck_ReversalOS: "",
        CL_Recheck_OnlandOS: "",
        CL_Recheck_EdgeOS: "",

        CL_Recheck_MatchingOD: "",
        CL_Recheck_WearingOD: "",
        CL_Recheck_MatchingOS: "",
        CL_Recheck_WearingOS: ""
      },
      zxdw: [
        {
          value: '中央，覆盖瞳孔',
          label: '中央，覆盖瞳孔'
        },
        {
          value: '中央略下方，覆盖瞳孔',
          label: '中央略下方，覆盖瞳孔'
        },
        {
          value: '镜片低于角膜下缘',
          label: '镜片低于角膜下缘'
        },
        {
          value: '上偏',
          label: '上偏'
        },
        {
          value: '鼻偏',
          label: '鼻偏'
        },
        {
          value: '颞偏',
          label: '颞偏'
        }
      ],
      ydd: [
        {
          value: '好，约等于1~2mm',
          label: '好，约等于1~2mm'
        },
        {
          value: '差，<1mm',
          label: '差，<1mm'
        },
        {
          value: '不动',
          label: '不动'
        },
        {
          value: '荣光素不进',
          label: '荣光素不进'
        },
        {
          value: '大，>2mm',
          label: '大，>2mm'
        },
        {
          value: '垂直顺滑',
          label: '垂直顺滑'
        },
        {
          value: '动摇不定，易偏位',
          label: '动摇不定，易偏位'
        },
        {
          value: '顶部旋转',
          label: '顶部旋转'
        },
        {
          value: '眼部控制',
          label: '眼部控制'
        }
      ],
      lyjh: [
        {
          value: '好',
          label: '好'
        },
        {
          value: '可接受',
          label: '可接受'
        },
        {
          value: '差',
          label: '差'
        }
      ],

      zygxq: [
        {
          value: '无或淡荧光区，直径约4mm',
          label: '无或淡荧光区，直径约4mm'
        },
        {
          value: '<4mm，与反转区分界不清',
          label: '<4mm，与反转区分界不清'
        },
        {
          value: '出现荧光积聚',
          label: '出现荧光积聚'
        },
        {
          value: '中央荧光素较多且弥散',
          label: '中央荧光素较多且弥散'
        },
        {
          value: '淡荧光染色，呈椭圆形',
          label: '淡荧光染色，呈椭圆形'
        }
      ],
      fzq: [
        {
          value: '均匀荧光亮区，宽约0.5-1mm',
          label: '均匀荧光亮区，宽约0.5-1mm'
        },
        {
          value: '荧光宽带，与平行弧区边界极清',
          label: '荧光宽带，与平行弧区边界极清'
        },
        {
          value: '有较大气泡不易排出',
          label: '有较大气泡不易排出'
        },
        {
          value: '荧光素较多，各弧段边界不清',
          label: '荧光素较多，各弧段边界不清'
        },
        {
          value: '下方染色积液',
          label: '下方染色积液'
        },
        {
          value: '上、下方染色，左、右区较细',
          label: '上、下方染色，左、右区较细'
        }
      ],
      zlq: [
        {
          value: '全周与角膜平行接触，呈均匀淡荧光环，约1mm',
          label: '全周与角膜平行接触，呈均匀淡荧光环，约1mm'
        },
        {
          value: '与角膜接触过紧，呈荧光黑区',
          label: '与角膜接触过紧，呈荧光黑区'
        },
        {
          value: '与角膜非环形接触，荧光环不完整',
          label: '与角膜非环形接触，荧光环不完整'
        },
        {
          value: '荧光逃逸',
          label: '荧光逃逸'
        }
      ],
      byq: [
        {
          value: '宽约0.5mm的荧光区亮',
          label: '宽约0.5mm的荧光区亮'
        },
        {
          value: '荧光带较细',
          label: '荧光带较细'
        },
        {
          value: '荧光带消失',
          label: '荧光带消失'
        },
        {
          value: '镜片边缘翘起，宽荧光带',
          label: '镜片边缘翘起，宽荧光带'
        },
        {
          value: '可见气泡',
          label: '可见气泡'
        }
      ],

      pdqk: [
        {
          value: '好',
          label: '好'
        },
        {
          value: '陡峭拒绝',
          label: '陡峭拒绝'
        },
        {
          value: '平坦拒绝',
          label: '平坦拒绝'
        },
        {
          value: '陡峭可接受',
          label: '陡峭可接受'
        },
        {
          value: '平坦可接受',
          label: '平坦可接受'
        }
      ],
      jpzj: [
        {
          value: '合适',
          label: '合适'
        },
        {
          value: '偏大',
          label: '偏大'
        },
        {
          value: '偏小',
          label: '偏小'
        }
      ],
    }
  },
  watch: {
    postData: {
      handler(n, o) {
        this.$store.commit('corneaContact/upCorneaReview', n)
      },
      deep: true
    },
    "$store.state.corneaContact.isfcshowdata": {
      deep: true,
      handler(n, o) {
        if (!n) {return false}
        for (let key in this.postData) {
          this.postData[key] = this.$store.state.corneaContact.corneaReview[key]
        }
      }
    }
  },
  created() {
    // this.postData = this.$store.state.corneaContact.corneaReview
  }
}
</script>

<style scoped lang="scss">
  .mar-20 {margin-top: 30px}
  .padding-20{padding-top: 15px;}
  .srA {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    .bts {padding-top: 1em; padding-right: .3rem}
    p {text-align: left;padding-top: .2rem;}
    .content-sr {
      display: flex;
      justify-content: start;
      align-items: center;
      li {
        width: 8vw;
        margin-right: 40px;
        input {
          display: block;
          width: 100%;
          outline: none;
          border: 1px solid #000000;
          border-radius: 5px;
          padding: 5px 0;
          text-align: center;
        }
      }
      li:nth-child(1) {
        width: 3vw;
        text-align: left;
        margin-right: 5px;
      }
    }
    .xz {
      li {width: 13vw}
    }
    .tj {
      input {
        display: block;
        width: 100%;
        outline: none;
        border: 1px solid #000000;
        border-radius: 5px;
        padding: 5px 0;
        text-align: center;
        margin-top: 21px;
      }
    }
    .last {
      margin-left: 50px;
      margin-top: 32px;
    }
  }
  .bottom {padding-top: 1rem;}
</style>
